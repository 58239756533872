import { Popover } from '@headlessui/react';
import {
  CurrencyDollarIcon,
  GlobeAltIcon,
  UserCircleIcon
} from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import fly from '$animations/fly';
import flyFromRight from '$animations/flyFromRight';
import useT from '$hooks/useT';
import classNames from '$lib/classNames';

function LanguageButton() {
  const router = useRouter();
  const { locale } = useT();

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="flex items-center">
            <motion.button type="button" {...flyFromRight}>
              <GlobeAltIcon className="h-6 w-6 text-slate-50" />
            </motion.button>
          </Popover.Button>

          <AnimatePresence exitBeforeEnter>
            {open && (
              <motion.div {...flyFromRight}>
                <Popover.Panel
                  static
                  className="absolute z-10 -translate-x-full"
                >
                  <div className="flex w-20 flex-col space-y-3 rounded bg-slate-700 p-3 text-sm">
                    <button
                      type="button"
                      className="flex justify-center"
                      onClick={() => {
                        router.replace(router.pathname, undefined, {
                          locale: 'zh',
                        });
                      }}
                    >
                      <div
                        className={classNames(
                          locale == 'zh' &&
                            'border-b border-amber-500 text-amber-500'
                        )}
                      >
                        正體中文
                      </div>
                    </button>
                    <button
                      type="button"
                      className="flex justify-center"
                      onClick={() => {
                        router.replace(router.pathname, undefined, {
                          locale: 'en',
                        });
                      }}
                    >
                      <div
                        className={classNames(
                          locale == 'en' &&
                            'border-b border-amber-500 text-amber-500'
                        )}
                      >
                        Eng
                      </div>
                    </button>
                  </div>
                </Popover.Panel>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}
    </Popover>
  );
}

export default function Header() {
  const { t } = useT();
  const router = useRouter();

  return (
    <header className="flex h-16 items-center justify-between pb-4 text-xl">
      <Link href="/" locale={router.locale}>
        <motion.a
          layoutId="title"
          className={classNames('flex items-end space-x-3')}
          {...fly}
        >
          <h1 className="text-2xl">{t('splity')}</h1>
          <CurrencyDollarIcon className="h-6 w-6 animate-bounce text-amber-500" />
        </motion.a>
      </Link>

      <div className="flex items-center justify-between space-x-3">
        <LanguageButton />

        <AnimatePresence>
          <Link href="/user" locale={router.locale}>
            <motion.button className="flex items-center" {...flyFromRight}>
              <UserCircleIcon className="h-6 w-6 text-slate-50" />
            </motion.button>
          </Link>
        </AnimatePresence>
      </div>
    </header>
  );
}
