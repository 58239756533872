import Header from '$components/Header';
import classNames from '$lib/classNames';
import Head from 'next/head';
import React, { ReactNode } from 'react';

interface LayoutProps {
  className?: string;
  children?: ReactNode;
  noHeader?: boolean;
}

export default function Layout({ className, children, noHeader }: LayoutProps) {
  return (
    <div
      className={classNames(
        'flex h-full w-full flex-col p-6 text-neutral-800',
        className
      )}
    >
      <Head>
        <title>CMS | Amoeba</title>
      </Head>
      {!noHeader && <Header />}
      {children}
    </div>
  );
}
