import { sentenceCase } from 'change-case';
import Joi from 'joi';
import { FormEventHandler, useState } from 'react';

type Error = {
  key: string;
  msg: string | any;
};

type FormParams = {
  defaultValue: { [key: string]: any };
  validateMapping: { [key: string]: any };
  errorMsg: { [key: string]: string };
  onSubmit(
    val: { [key: string]: any },
    isValid: boolean,
    errors: Error[]
  ): void;
};

export default function useForm({
  defaultValue = {},
  validateMapping,
  errorMsg,
  onSubmit,
}: FormParams) {
  const [value, setValue] = useState(defaultValue);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [errors, setErrors] = useState<Error[]>([]);

  const getError = (key: string) => {
    return errors.find((err) => err.key === key)?.msg;
  };

  const setVal = (key: string, val: any) => {
    setValue({
      ...value,
      [key]: val,
    });
  };

  const replaceVal = (val: any) => {
    setValue(val);
  };

  const validate = async (): Promise<
    [boolean, { key: string; msg: any }[]]
  > => {
    if (value == null && validateMapping == null) return [true, []];
    if (value == null && validateMapping != null) return [false, []];
    try {
      setIsValidating(true);
      const keys = Object.keys(value);
      let errors: { key: string; msg: any }[] = [];
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        if (validateMapping[key] == null) continue;
        if (Joi.isSchema(validateMapping[key])) {
          const schema = validateMapping[key];
          const result = schema.validate(value[key]);
          if (result.error) {
            const errMsg = result.error.details[0].message.replace(
              'value',
              sentenceCase(key)
            );
            console.log(errMsg);
            errors.push({
              key,
              msg: errorMsg[key] || errMsg,
            });
          }
        } else {
          const func = validateMapping[key];
          try {
            await func(value[key]);
          } catch (error) {
            errors.push({
              key,
              msg: errorMsg[key] || error,
            });
          }
        }
      }
      setErrors(errors);
      setIsValidating(false);
      return [errors.length === 0, errors];
    } catch (error: any) {
      console.error(error);
      setIsValidating(false);
      return [false, []];
    }
  };

  const onFormSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const [isValid, errors] = await validate();
    onSubmit(value, isValid, errors);
  };

  return {
    value,
    setVal,
    replaceVal,
    validate,
    isValidating,
    errors,
    getError,
    onFormSubmit,
  };
}
