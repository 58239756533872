import { Dispatch, SetStateAction, useState } from 'react';

export default function useLoading(): [
  boolean,
  Dispatch<SetStateAction<boolean>>
] {
  const [loading, setIsLoading] = useState<boolean>(false);

  return [loading, setIsLoading];
}
