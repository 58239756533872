import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { motion } from 'framer-motion';
import { ChangeEventHandler } from 'react';
import classNames from '$lib/classNames';
import { PlusIcon } from '@heroicons/react/outline';

interface InputProps {
  className?: string;
  labelClassName?: string;
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  type?: string;
  pattern?: string;
  inputMode?:
    | 'text'
    | 'none'
    | 'search'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | undefined;
  error?: string;
  hint?: string;
  animationCustom?: number;
  withBtn?: boolean;
  onBtnClick?(): void;
  required?: boolean;
  readOnly?: boolean;
  min?: number;
  max?: number;
}

export default function Input({
  className,
  id,
  name,
  type,
  label,
  placeholder,
  error,
  hint,
  value,
  onChange,
  withBtn,
  onBtnClick,
  required,
  readOnly,
  min,
  max,
}: InputProps) {
  return (
    <motion.div className={className}>
      <motion.label
        htmlFor={id}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
        {required && '*'}
      </motion.label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          type={type}
          name={name}
          id={id}
          className={classNames(
            'block w-full rounded-md border p-2 focus:outline-none sm:text-sm',
            error
              ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-1 focus:ring-red-500'
              : readOnly
              ? 'border-neutral-200 bg-neutral-200 text-neutral-500'
              : 'border-neutral-200 focus:border-green-900 focus:ring-1 focus:ring-green-900'
          )}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
          readOnly={readOnly}
          min={min}
          max={max}
        />
        {withBtn && (
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={onBtnClick}
          >
            <PlusIcon className="h-5 w-5 text-green-700" aria-hidden="true" />
          </button>
        )}
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <p
        className={classNames(
          'mt-1 text-sm',
          error && ' text-red-600',
          hint && 'text-gray-500'
        )}
      >
        {error || hint}
      </p>
    </motion.div>
  );
}
